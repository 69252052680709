import * as React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';

const Interoperability = () => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.interoperability}`,
    message: (
      <div className="ui eight wide column">
        <div className="column">
          <div className="ui text container">
            <div className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
              {messages.message_interoperability_p1}
              <br/>
              <div className="ui bulleted list">
                <div className="item">{messages.message_interoperability_item1}</div>
                <div className="item">{messages.message_interoperability_item2}</div>
                <div className="item">{messages.message_interoperability_item3}</div>
                <div className="item">{messages.message_interoperability_item4}</div>
              </div>
              <br/>
              {messages.message_interoperability_p2}
              <br/>
              <div className="ui bulleted list">
                <div className="item">{messages.message_interoperability_item5}</div>
                <div className="item">{messages.message_interoperability_item6}</div>
                <div className="item">{messages.message_interoperability_item7}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.interoperability}`} textBlocks={textBlocks}/>);
};

export default Interoperability;